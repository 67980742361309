import * as api from "../api";
import {
    AUTHENTICATION,
    AUTHENTICATION_ERROR
} from "../constants/actionTypes";
import { auth, googleProvider } from "../pages/AuthForm/firebaseConfig";
import { signInWithPopup } from "firebase/auth";

const signup = (formValues, navigate) => async dispatch => {
    console.log("formvalues", formValues)
    console.log("api signup", api.signup)
    console.log(api.signup(formValues))
    //const { data } = await api.signup(formValues);
    //console.log(data)
    try {
        const { data } = await api.signup(formValues);
        dispatch({
            type: AUTHENTICATION,
            data: data
        });
        navigate("/");
    } catch (error) {
        //console.log(error);
        console.error('Error during signup:', error);
        if (error.response) {
            // Handle errors thrown from the server, like a 400 or 500 series response.
            console.error('Server responded with:', error.response);
        }
        if (error.request) {
            // The request was made but no response was received.
            console.error('Request made, no response:', error.request);
        }
        dispatch({
            type: AUTHENTICATION_ERROR,
            error: error.message
        });
    }
};

const login = (formValues, navigate) => async dispatch => {
    console.log("formvalues", formValues)
    try {
        const { data } = await api.login(formValues);
        dispatch({
            type: AUTHENTICATION,
            data: data
        });
        navigate("/");
    } catch (error) {
        console.log(error);
        dispatch({
            type: AUTHENTICATION_ERROR,
            error: error.message
        });
    }
};

const googleLogin = (navigate) => async dispatch => {
    try {
        const result = await signInWithPopup(auth, googleProvider);
        const user = result.user;
        const token = await user.getIdToken(); // Get the ID token from Firebase

        console.log("user", user)
        // Send token and user data to backend server
        const { data } = await api.googleLogin({ token, email: user.email, name: user.displayName });

        dispatch({
            type: AUTHENTICATION,
            data: data
        });
        navigate("/");
    } catch (error) {
        console.log(error);
        dispatch({
            type: AUTHENTICATION_ERROR,
            error: error.message
        });
    }
};


export { login, signup, googleLogin };