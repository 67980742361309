import React, { useState, useEffect, useCallback } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
import { Layout, Button, Avatar, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { jwtDecode } from 'jwt-decode';

import logo from '../../img/PetCrunch.png';
import './Navbar.css'; // Create a CSS file for styling
import { LOGOUT } from '../../constants/actionTypes';

const { Header } = Layout;
const { Title } = Typography;

const Navbar = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));

  const logout = useCallback(() => {
    dispatch({ type: LOGOUT });
    navigate("/authform");
    setUser(null);
  }, [dispatch, navigate]);

  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }
    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [location, logout, user?.token]);

  return (
    <Header className="navbar">
      <div className="navbar-logo">
        <NavLink to="/" className="navbar-logo-link">
          <img src={logo} alt="PetCrunch Logo" className="navbar-logo-image" />
          <h3 className="navbar-logo-text">
              <span className="highlight">Pet</span>Crunch
          </h3>
        </NavLink>
      </div>

      <ul className="navWrapper">
        {!user ? (
          <li>
          <NavLink to="/authform" exact className="navLink" activeClassName="active">
            <FaUserCircle size={35} />
            <span>Sign In</span>
          </NavLink>
        </li>
        ) : (
          <li className="user-info">
            <Avatar className="avatar" alt="username" size="large">
              {user?.result?.username?.charAt(0).toUpperCase()}
            </Avatar>
            <Title level={4} className="username">
              {user?.result?.username}
            </Title>
            <Button className="logout-button" onClick={logout} htmlType='button'>
              Log Out
            </Button>
          </li>
        )}
      </ul>
    </Header>
  );
};

export default Navbar;