import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import { thunk } from "redux-thunk";
import reducers from "./reducers"

//const root = ReactDOM.createRoot(document.getElementById('root'));

const store = createStore(reducers, compose(applyMiddleware(thunk)));
const container = document.getElementById('root');
const root = createRoot(container);

console.log("store", store);

// root.render(
//     <App />
// );





root.render(
    <Provider store={store}>
        <App />
    </Provider>
);