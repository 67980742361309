import React from 'react';
import './Home.css';
import screenshot from '../../img/meallist_trans.png'; 
import plotBackground from '../../img/plotbackground.jpg'; 
import plotGeneral from '../../img/plotgeneral.jpg'; 
import appStoreBadge from '../../img/app-store-badge.png';  
import Nutrient from './Nutrient'; 
import plotai from '../../img/plotai-removebg.png';
import plotVet from '../../img/catvet.jpg';

const Home = () => {
  return (
    <div className="home-container">
      {/* Hero Section */}
      <section className="hero" style={{ backgroundImage: `url(${plotBackground})` }}>
        <div className="hero-content">
          <div className="hero-text-bg">
            <h1>Your Pet's Health, Simplified</h1>
            <p>Log diets, track wellness, and ensure optimal nutrition effortlessly.</p>
            <button className="hero-button">Begin Your Free Trial</button>
          </div>
          <div className="hero-image">
            <img src={screenshot} alt="PetCrunch App Screenshot" className="app-screenshot" />
          </div>
        </div>
      </section>

      {/* General Section */}
      <section className="general-section">
          <div className="general-plot">
            <img src={plotGeneral} alt="General Plot" className="plot-image" />
          </div>
          <div className="general-text">
            <h2>Product Overview</h2>
            <h1>Organize Your Pet's Food Intake</h1>
            <p>
              In a world filled with excellent resources on pet nutrition (e.g.,
              <a href="https://cats.com/the-complete-guide-to-feline-nutrition" target="_blank" rel="noopener noreferrer">cats.com</a> 
              ) and homemade food recipes, 
              PetCrunch goes a step further. While many guides help you decide what to feed your pets, 
              our product is designed to monitor their day-to-day nutritional intake, 
              ensuring that your pets are actually receiving the recommended feeding. 
              By tracking all kinds of feeding options (e.g., commercial foods and self-prepared meals), 
              PetCrunch helps you maintain a balanced diet for your pets, 
              making sure that they get the best nutrition possible.
            </p>
            <ul>
              <li><a href="#nutrientbalance">Learn About Nutrient Balance</a></li>
            </ul>
          </div>
      </section>

      {/* Nutrient Balance Section */}
      <section className="nutrient-balance-section" id="nutrientbalance">
        <Nutrient />
      </section>

      {/* AI Section */}
      <section className="ai-section">
          <div className="ai-plot">
            <img src={plotai} alt="AI Plot" className="ai-image" />
          </div>
          <div className="ai-text">
            <h2>AI-Powered Solution</h2>
            <h1>Tailored Nutrition Monitoring</h1>
            <p>
            At PetCrunch, we understand that every pet has its own unique personality and needs that make them one of a kind. 
            Our app is customized to cater to your pet's specific nutritional and health requirements. 
            For example, when your vet advises you to "observe" symptoms like vomiting or diarrhea during your annual examination, PetCrunch steps in as your experienced, objective observer. Our AI-powered solution not only helps you keep a close eye on your pet's health but also provides tailored advice based on the symptoms you record, giving you peace of mind that your pets are being cared for with the utmost precision.
            </p>

            <ul>
              <li><a href="#aisolution">Try Our Product with a Test User Profile</a></li>
            </ul>
          </div>
      </section>
      
      


      {/* Download Section */}
      <section className="download-section">
        <h2>Download the PetCrunch App</h2>
        <p>
          Our app is currently available on iOS, and we're actively working on bringing it to Android. 
          If you're an Android user and would love to see PetCrunch on your device, we'd appreciate it if you could take a moment to 
          <a href="https://example.com/survey" target="_blank" rel="noopener noreferrer" className="survey-link"> fill out this short survey</a>.
          Your feedback will help us prioritize our development efforts and notify you when the Android version is ready!
        </p>
        <div className="download-buttons">
          <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
            <img src={appStoreBadge} alt="Download on the App Store" className="download-badge" />
          </a>
        </div>
      </section>
      
    </div>
  );
};

export default Home;
