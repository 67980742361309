import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyAZbW6mEudfDcQ4xKNcPvqFY47-9SEd1Sk",
    authDomain: "petcrunch.firebaseapp.com",
    projectId: "petcrunch",
    storageBucket: "petcrunch.appspot.com",
    messagingSenderId: "1049712128143",
    appId: "1:1049712128143:web:c4cff91865af79f1db00fa",
    measurementId: "G-Y0SE7SPV6S"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth();

const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider, analytics };