import React from 'react';

const Profile = () => {
  return (
    <div>
      <h1>Welcome to Your Profile</h1>
      <p>This is the profile page.</p>
    </div>
  );
};

export default Profile;
