import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './AuthForm.css';
import { FaGoogle } from 'react-icons/fa';
import { auth, googleProvider } from './firebaseConfig';
import { signInWithPopup, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { signup, login, googleLogin } from '../../actions/autentication';

const AuthForm = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetEmailSent, setResetEmailSent] = useState(false);
  
  const navigate = useNavigate(); // Initialize useNavigate
  const dispatch = useDispatch();

  const handleToggle = () => {
    setIsSignUp(!isSignUp);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isSignUp) {
        if (password !== confirmPassword) {
          alert('Passwords do not match. Please try again.');
          return;
        }
        // Handle sign up logic here (e.g., createUserWithEmailAndPassword)
        const formValues = { username: email.split('@')[0], email, password, confirmPassword };
        dispatch(signup(formValues, navigate));

      } else {
        // await signInWithEmailAndPassword(auth, email, password);
        // navigate('/profile'); // Redirect to the Profile page
        const formValues = { email, password };
        dispatch(login(formValues, navigate));
        navigate("/");
      }
    } catch (error) {
      console.error('Error signing in:', error);
      alert('Failed to sign in. Please check your credentials and try again.');
    }
  };

  const handleSocialLogin = async (provider) => {
    try {
      // await signInWithPopup(auth, provider);
      // navigate('/profile'); // Redirect to the Profile page
      dispatch(googleLogin(navigate));
      navigate("/");
    } catch (error) {
      console.error('Error during Google login:', error);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      alert('Please enter your email address.');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setResetEmailSent(true);
      alert('Password reset email sent! Please check your inbox.');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      alert('Error sending password reset email. Please try again later.');
    }
  };

  return (
    <div className="auth-container">
      <h2>{isSignUp ? 'Create an Account' : 'Sign In'}</h2>
      <form onSubmit={handleSubmit} className="auth-form">
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {isSignUp && (
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password:</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
        )}
        <button type="submit" className="auth-button">
          {isSignUp ? 'Sign Up' : 'Sign In'}
        </button>
      </form>
      {!isSignUp && (
        <p className="forgot-password">
          <button onClick={handleForgotPassword} className="forgot-password-button">
            Forgot Password?
          </button>
        </p>
      )}
      {resetEmailSent && (
        <p className="reset-confirmation">
          If this email is registered, you will receive a password reset link.
        </p>
      )}
      <p className="toggle-auth">
        {isSignUp ? 'Already have an account?' : "Don't have an account?"}{' '}
        <button onClick={handleToggle} className="toggle-button">
          {isSignUp ? 'Sign In' : 'Sign Up'}
        </button>
      </p>
      <div className="social-login">
        <p>Or sign in with:</p>
        <div className="social-buttons">
          <button
            onClick={() => handleSocialLogin(googleProvider)}
            className="social-button google"
          >
            <FaGoogle /> Google
          </button>
        </div>
      </div>
    </div>
  );
};

export default AuthForm;
