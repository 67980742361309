import React, { useState } from 'react';
import './Nutrient.css'; 
import catSilhouette from '../../img/catprofile.png'; 
import diagnose from '../../img/diagnose.svg';

const CatProfile = () => {
  const [expandedCategory, setExpandedCategory] = useState(null);
  //const [catColor, setCatColor] = useState('#e5ba86');
  const [catColor, setCatColor] = useState(50);
  const [signs, setSigns] = useState('');
  const [selectedButton, setSelectedButton] = useState(null);

  const toggleCategory = (category) => {
    setExpandedCategory(expandedCategory === category ? null : category);
    setSigns('');
  };

  const getFilterValue = () => {
    const brightness = 1.5 - (catColor / 100);
    const contrast = 1 + (catColor / 300);
    return `brightness(${brightness}) contrast(${contrast})`;
  };

  const handleButtonClick = (ingredient, amount) => {
    setSelectedButton(`${ingredient}-${amount}`);
    switch (ingredient) {
        case 'Vitamin A':
            if (amount === 'deficiency') {
                setCatColor(0);
                setSigns('Conjunctivitis/Cataracts/Eye Problem/Weight Loss');
            } else if (amount === 'excess') {
                setCatColor(100);
                setSigns('Osteoporosis (bone problem)');
            }
            break;
        case 'Vitamin D':
            if (amount === 'deficiency') {
                setCatColor(0);
                setSigns('Bone Problem/Progressive Paralysis/Lack of Grooming/Weight Loss/Decreased Appetite');
            } else if (amount === 'excess') {
                setCatColor(100);
                setSigns('Anorexia/Vomiting/Lethargy');
            }
            break;
        case 'Vitamin E':
            if (amount === 'deficiency') {
                setCatColor(0);
                setSigns('Anorexia/Depression/Abdominal Tenderness/Fat Disorders');
            }
            break;
        case 'Vitamin K':
            if (amount === 'deficiency') {
                setCatColor(0);
                setSigns('Slow clotting/Bleeding');
            }
            break;
        case 'Vitamin B1':
            if (amount === 'deficiency') {
                setCatColor(0);
                setSigns('Arrhythmia/Seizures/Nerve Damage/Reflex Changes');
            }
            break;
        case 'Vitamin B2':
            if (amount === 'deficiency') {
                setCatColor(0);
                setSigns('Cataracts/Fatty Livers');
            }
            break;
        case 'Vitamin B6':
            if (amount === 'deficiency') {
                setCatColor(0);
                setSigns('Growth Delay/Seizures/Kidney Damage');
            }
            break;
        case 'Niacin':
            if (amount === 'deficiency') {
                setCatColor(0);
                setSigns('Anorexia/Weight Loss/Fever/Inflamed Tongue');
            }
            break;
        case 'Pantothenic Acid':
            if (amount === 'deficiency') {
                setCatColor(0);
                setSigns('Growth Delay/Fatty Livers/Instestinal Problems');
            }
            break;
        case 'Vitamin B12':
            if (amount === 'deficiency') {
                setCatColor(0);
                setSigns('Weight Loss/Vomiting/Diarrhea/Instestinal Problems');
            }
            break;
        case 'Folic Acid':
            if (amount === 'deficiency') {
                setCatColor(0);
                setSigns('Growth Delay/Increased Iron in Blood');
            }
            break;
        case 'Calcium':
            if (amount === 'deficiency') {
                setCatColor(0);
                setSigns('Parathyroid disorder/ Bone Problems');
            } else if (amount === 'excess') {
                setCatColor(100);
                setSigns('Loss of Appetite/Growth Delay/Bone Hardening/Magnesium Deficiency');
            }
            break;
        case 'Phosphorus':
            if (amount === 'deficiency') {
                setCatColor(0);
                setSigns('Red Blood Cell Destruction/Movement Issues/Acid Imbalance');
            }
            break;
        case 'Magnesium':
            if (amount === 'deficiency') {
                setCatColor(0);
                setSigns('Growth Delay/Joint Hyperextension/Muscle Twitching/Seizures');
            } else if (amount === 'excess') {
                setCatColor(100);
                setSigns('Alkaline Kidney Stones');
            }
            break;
        case 'Sodium':
            if (amount === 'deficiency') {
                setCatColor(0);
                setSigns('Anorexia/Growth Delay/Excessive Thirst, Drinking, and Urination');
            }
            break;
        case 'Potassium':
            if (amount === 'deficiency') {
                setCatColor(0);
                setSigns('Anorexia/Growth Delay/Nerve Issues/Coordination Loss');
            }
            break;
        case 'Chlorine':
            if (amount === 'deficiency') {
                setCatColor(0);
                setSigns('Sodium Retention/Potassium Loss');
            }
            break;
        case 'Iron':
            if (amount === 'deficiency') {
                setCatColor(0);
                setSigns('Growth Delay/Pale mucous membranes/Fatigue/Weakness/Diarrhea');
            } else if (amount === 'excess') {
                setCatColor(100);
                setSigns('Vomiting/Diarrhea');
            }
            break;
        case 'Copper':
            if (amount === 'deficiency') {
                setCatColor(0);
                setSigns('Growth Delay/Fertility Delay');
            }
            break;
        case 'Zinc':
            if (amount === 'deficiency') {
                setCatColor(0);
                setSigns('Skin Lesions/Growth Delay');
            }
            break;
        case 'Iodine':
            if (amount === 'deficiency') {
                setCatColor(0);
                setSigns('Goiter');
            } else if (amount === 'excess') {
                setCatColor(100);
                setSigns('Excessive Tearing, Salivation, and Nasal Discharge/Dandruff');
            }
            break;
        default:
            setCatColor('#e5ba86');
            setSigns('');
    }
  }

  return (
    <div className="cat-profile-container">
      
      <div className="expanded-container">
        {!expandedCategory && (
            <div>
                <h1>Cat Nutrient Profile</h1>
                <p style={{ fontSize: '18px' }}>
                Understanding the importance of proper nutrition for your cat is crucial to 
                ensuring their long-term health and well-being.
                Nutrient balance is key—both deficiencies and excesses can lead to serious health issues over time.
                Signs of nutrient imbalance can include symptoms such as 
                lethargy, weight changes, or digestive problems.
                Therefore, it's important to monitor carefully whether your cat is within the 
                recommended range for each nutrient, especially considering the cumulative effects over time.
                </p>
                <p style={{ fontSize: '14px' }}>
                <strong>Note:</strong> Values refer to daily recommended needs for an adult cat weighing 9 pounds.
                Ensuring that your cat's diet meets these standards can help prevent potential 
                health problems and promote a longer, healthier life.
                </p>
                <p style={{ fontSize: '14px', fontStyle: 'italic' }}>
                Citation: Beitz, D. C., Bauer, J. E., Behnke, K. C., Dzanis, D. A., Fahey, G. C., Hill, R. C., ... & Rogers, Q. R. (2006). Your Cat’s Nutritional Needs: A Science-Based Guide for Pet Owners. National Research Council, National Academy of Sciences, 1-16.
                </p>
            </div>
          
        )}
        <div className="categories">
            <div 
                className={`category ${expandedCategory === 'Protein' ? 'selected' : ''}`}
                onClick={() => toggleCategory('Protein')}
            >
                Protein
            </div>
            <div 
                className={`category ${expandedCategory === 'Fat' ? 'selected' : ''}`}
                onClick={() => toggleCategory('Fat')}
            >
                Fat
            </div>
            <div 
                className={`category ${expandedCategory === 'Vitamins' ? 'selected' : ''}`}
                onClick={() => toggleCategory('Vitamins')}
            >
                Vitamins
            </div>
            <div 
                className={`category ${expandedCategory === 'Minerals' ? 'selected' : ''}`}
                onClick={() => toggleCategory('Minerals')}
            >
                Minerals
            </div>
            
        </div>

      {expandedCategory === 'Protein' && (
        <div className="expanded-category">
          <div className="ingredient">
            <h4>Crude Protein: 12.5 g</h4>
            <div className="spectrum-container">
                <div className='spectrum'>
                    <div className='spectrum-twoends'></div>
                </div>
            </div>
          </div>
        </div>
      )}
      {expandedCategory === 'Fat' && (
        <div className="expanded-category">
          <div className="ingredient">
            <h4>Total Fat: 5.5g</h4>
            <div className="spectrum-container">
                <div className='spectrum'>
                    <div className='spectrum-twoends'></div>
                </div>
            </div>
          </div>
        </div>
      )}
      {expandedCategory === 'Vitamins' && (
        <div className="expanded-category">
          <div className="ingredient">
            <h4>Vitamin A: 63 µg</h4>
            <div className="spectrum-container">
                <button
                    className={`deficiency-button ${selectedButton === 'Vitamin A-deficiency' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Vitamin A', 'deficiency')}
                >
                    Deficiency
                </button>
                <div className='spectrum'>
                    <div className='spectrum-twoends'></div>
                </div>
                <button
                    className={`excess-button ${selectedButton === 'Vitamin A-excess' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Vitamin A', 'excess')}
                >
                    Excess
                </button>
            </div>
          </div>

          <div className="ingredient">
            <h4>Vitamin D: 0.4 µg</h4>
            <div className="spectrum-container">
                <button
                    className={`deficiency-button ${selectedButton === 'Vitamin D-deficiency' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Vitamin D', 'deficiency')}
                >
                    Deficiency
                </button>
                <div className='spectrum'>
                    <div className='spectrum-twoends'></div>
                </div>
                <button
                    className={`excess-button ${selectedButton === 'Vitamin D-excess' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Vitamin D', 'excess')}
                >
                    Excess
                </button>
            </div>
          </div>

          <div className="ingredient">
            <h4>Vitamin E: 2.5 mg</h4>
            <div className="spectrum-container">
                <button
                    className={`deficiency-button ${selectedButton === 'Vitamin E-deficiency' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Vitamin E', 'deficiency')}
                >
                    Deficiency
                </button>
                <div className='spectrum'>
                    <div className='spectrum-deficiency'></div>
                </div>
                <button
                    className='placeholder-button'
                    onClick={() => handleButtonClick('Vitamin A', 'excess')}
                >Excess
                </button>
            </div>
          </div>

          <div className="ingredient">
            <h4>Vitamin K: 82 µg</h4>
            <div className="spectrum-container">
                <button
                    className={`deficiency-button ${selectedButton === 'Vitamin K-deficiency' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Vitamin K', 'deficiency')}
                >
                    Deficiency
                </button>
                <div className='spectrum'>
                    <div className='spectrum-deficiency'></div>
                </div>
                <button
                    className='placeholder-button'
                >Excess
                </button>
            </div>
          </div>

          <div className="ingredient">
            <h4>Vitamin B1: 0.33 mg</h4>
            <div className="spectrum-container">
                <button
                    className={`deficiency-button ${selectedButton === 'Vitamin B1-deficiency' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Vitamin B1', 'deficiency')}
                >
                    Deficiency
                </button>
                <div className='spectrum'>
                    <div className='spectrum-deficiency'></div>
                </div>
                <button
                    className='placeholder-button'
                >Excess
                </button>
            </div>
          </div>

          <div className="ingredient">
            <h4>Vitamin B2: 0.27 mg</h4>
            <div className="spectrum-container">
                <button
                    className={`deficiency-button ${selectedButton === 'Vitamin B2-deficiency' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Vitamin B2', 'deficiency')}
                >
                    Deficiency
                </button>
                <div className='spectrum'>
                    <div className='spectrum-deficiency'></div>
                </div>
                <button
                    className='placeholder-button'
                >Excess
                </button>
            </div>
          </div>

          <div className="ingredient">
            <h4>Vitamin B6: 0.16 mg</h4>
            <div className="spectrum-container">
                <button
                    className={`deficiency-button ${selectedButton === 'Vitamin B6-deficiency' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Vitamin B6', 'deficiency')}
                >
                    Deficiency
                </button>
                <div className='spectrum'>
                    <div className='spectrum-deficiency'></div>
                </div>
                <button
                    className='placeholder-button'
                >Excess
                </button>
            </div>
          </div>

          <div className="ingredient">
            <h4>Niacin: 2.5 mg</h4>
            <div className="spectrum-container">
                <button
                    className={`deficiency-button ${selectedButton === 'Niacin-deficiency' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Niacin', 'deficiency')}
                >
                    Deficiency
                </button>
                <div className='spectrum'>
                    <div className='spectrum-deficiency'></div>
                </div>
                <button
                    className='placeholder-button'
                >Excess
                </button>
            </div>
          </div>

          <div className="ingredient">
            <h4>Pantothenic Acid: 0.4 mg</h4>
            <div className="spectrum-container">
                <button
                    className={`deficiency-button ${selectedButton === 'Pantothenic Acid-deficiency' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Pantothenic Acid', 'deficiency')}
                >
                    Deficiency
                </button>
                <div className='spectrum'>
                    <div className='spectrum-deficiency'></div>
                </div>
                <button
                    className='placeholder-button'
                >Excess
                </button>
            </div>
          </div>

          <div className="ingredient">
            <h4>Vitamin B12: 1.4 µg</h4>
            <div className="spectrum-container">
                <button
                    className={`deficiency-button ${selectedButton === 'Vitamin B12-deficiency' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Vitamin B12', 'deficiency')}
                >
                    Deficiency
                </button>
                <div className='spectrum'>
                    <div className='spectrum-deficiency'></div>
                </div>
                <button
                    className='placeholder-button'
                >Excess
                </button>
            </div>
          </div>

          <div className="ingredient">
            <h4>Folic Acid: 47 µg</h4>
            <div className="spectrum-container">
                <button
                    className={`deficiency-button ${selectedButton === 'Folic Acid-deficiency' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Folic Acid', 'deficiency')}
                >
                    Deficiency
                </button>
                <div className='spectrum'>
                    <div className='spectrum-deficiency'></div>
                </div>
                <button
                    className='placeholder-button'
                >Excess
                </button>
            </div>
          </div>

          
          
        </div>
      )}
      {expandedCategory === 'Minerals' && (
        <div className="expanded-category">
          <div className="ingredient">
            <h4>Calcium: 0.18 g</h4>
            <div className="spectrum-container">
                <button
                    className={`deficiency-button ${selectedButton === 'Calcium-deficiency' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Calcium', 'deficiency')}
                >Deficiency</button>
                <div className='spectrum'>
                    <div className='spectrum-twoends'></div>
                </div>
                <button
                    className={`excess-button ${selectedButton === 'Calcium-excess' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Calcium', 'excess')}
                >Excess</button>
            </div>
          </div>

          <div className="ingredient">
            <h4>Phosphorus: 0.16 g</h4>
            <div className="spectrum-container">
                <button
                    className={`deficiency-button ${selectedButton === 'Phosphorus-deficiency' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Phosphorus', 'deficiency')}
                >Deficiency</button>
                <div className='spectrum'>
                    <div className='spectrum-deficiency'></div>
                </div>
                <button className='placeholder-button'>Excess</button>
            </div>
          </div>

          <div className="ingredient">
            <h4>Magnesium: 25 mg</h4>
            <div className="spectrum-container">
                <button
                    className={`deficiency-button ${selectedButton === 'Magnesium-deficiency' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Magnesium', 'deficiency')}
                >Deficiency</button>
                <div className='spectrum'>
                    <div className='spectrum-twoends'></div>
                </div>
                <button
                    className={`excess-button ${selectedButton === 'Magnesium-excess' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Magnesium', 'excess')}
                >Excess</button>
            </div>
          </div>

          <div className="ingredient">
            <h4>Sodium: 42 mg</h4>
            <div className="spectrum-container">
                <button
                    className={`deficiency-button ${selectedButton === 'Sodium-deficiency' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Sodium', 'deficiency')}
                >Deficiency</button>
                <div className='spectrum'>
                    <div className='spectrum-deficiency'></div>
                </div>
                <button className='placeholder-button'>Excess</button>
            </div>
          </div>

          <div className="ingredient">
            <h4>Potassium: 0.33 g</h4>
            <div className="spectrum-container">
                <button
                    className={`deficiency-button ${selectedButton === 'Potassium-deficiency' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Potassium', 'deficiency')}
                >Deficiency</button>
                <div className='spectrum'>
                    <div className='spectrum-deficiency'></div>
                </div>
                <button className='placeholder-button'>Excess</button>
            </div>
          </div>

          <div className="ingredient">
            <h4>Chlorine: 60 mg</h4>
            <div className="spectrum-container">
                <button
                    className={`deficiency-button ${selectedButton === 'Chlorine-deficiency' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Chlorine', 'deficiency')}
                >Deficiency</button>
                <div className='spectrum'>
                    <div className='spectrum-deficiency'></div>
                </div>
                <button className='placeholder-button'>Excess</button>
            </div>
          </div>

          <div className="ingredient">
            <h4>Iron: 5 mg</h4>
            <div className="spectrum-container">
                <button
                    className={`deficiency-button ${selectedButton === 'Iron-deficiency' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Iron', 'deficiency')}
                >Deficiency</button>
                <div className='spectrum'>
                    <div className='spectrum-twoends'></div>
                </div>
                <button
                    className={`excess-button ${selectedButton === 'Iron-excess' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Iron', 'excess')}
                >Excess</button>
            </div>
          </div>

          <div className="ingredient">
            <h4>Copper: 0.3 mg</h4>
            <div className="spectrum-container">
                <button
                    className={`deficiency-button ${selectedButton === 'Copper-deficiency' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Copper', 'deficiency')}
                >Deficiency</button>
                <div className='spectrum'>
                    <div className='spectrum-deficiency'></div>
                </div>
                <button className='placeholder-button'>Excess</button>
            </div>
          </div>

          <div className="ingredient">
            <h4>Zinc: 4.6 mg</h4>
            <div className="spectrum-container">
                <button
                    className={`deficiency-button ${selectedButton === 'Zinc-deficiency' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Zinc', 'deficiency')}
                >Deficiency</button>
                <div className='spectrum'>
                    <div className='spectrum-deficiency'></div>
                </div>
                <button className='placeholder-button'>Excess</button>
            </div>
          </div>

          <div className="ingredient">
            <h4>Manganese: 0.3 mg</h4>
            <div className="spectrum-container">
                <button
                    className='placeholder-button'
                >Deficiency</button>
                <div className='spectrum'>
                    <div className='spectrum-twoends'></div>
                </div>
                <button
                    className='placeholder-button'
                >Excess</button>
            </div>
          </div>

          <div className="ingredient">
            <h4>Selenium: 19 µg</h4>
            <div className="spectrum-container">
                <button
                    className='placeholder-button'
                >Deficiency</button>
                <div className='spectrum'>
                    <div className='spectrum-twoends'></div>
                </div>
                <button
                    className='placeholder-button'
                >Excess</button>
            </div>
          </div>

          <div className="ingredient">
            <h4>Iodine: 88 µg</h4>
            <div className="spectrum-container">
                <button
                    className={`deficiency-button ${selectedButton === 'Iodine-deficiency' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Iodine', 'deficiency')}
                >Deficiency</button>
                <div className='spectrum'>
                    <div className='spectrum-twoends'></div>
                </div>
                <button
                    className={`excess-button ${selectedButton === 'Iodine-excess' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('Iodine', 'excess')}
                >Excess</button>
            </div>
          </div>

          


        </div>
        )}
      </div>
      <div className="cat-silhouette-container">
        {/* <img 
          src={catSilhouette} 
          alt="Cat Silhouette" 
          className="cat-silhouette-image" 
          style={{ filter: getFilterValue()}}
        /> */}
        <div className="diagnose-image">
            <img src={diagnose} alt="Diagnose Background" className="diagnose-background" />
        </div>

        {signs && (
            <div className="symptoms-popup">
                {signs.split('/').map((sign, index) => (
                  <div key={index}>{sign}</div>
                ))}
            </div>
        )}
      </div>
    </div>
  );
};

export default CatProfile;

