import axios from "axios";

const api = axios.create({ baseURL: "http://localhost:5001" });

api.interceptors.request.use((req) => {
    //console.log("req", req);
    const profile = localStorage.getItem("profile");
    //console.log("localStorage profile", profile);
    if (profile) {
        req.headers.Authorization = `Bearer ${JSON.parse(profile).token}`;
    }
    req.headers['Content-Type'] = 'application/json'; // Ensure content type is set
    return req; // forgot to return...
});

export const login = async (formValues) => api.post("/users/login", formValues);
export const signup = async (formValues) => api.post("/users/signup", formValues);

export const googleLogin = async (googleUserData) => {
    return api.post("/users/googlelogin", googleUserData);
}

export default api;