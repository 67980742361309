import React from "react";
import './Footer.css';
import logo from '../../img/PetCrunch.png';
import { AiFillLinkedin, AiFillInstagram, AiFillYoutube } from 'react-icons/ai';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-top">
        <div className="footer-logo">
          <img src={logo} alt="PetCrunch Logo" className="footer-logo-image" />
        </div>
        <div className="footer-content">
          <h3>PetCrunch</h3>
          <p>Track your pet's diet and ensure their health with PetCrunch.</p>
          <div className="social-icons">
            <a href="https://www.linkedin.com/company/infoartisans/" target="_blank" rel="noopener noreferrer">
              <AiFillLinkedin size={30} />
            </a>
            <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
              <AiFillInstagram size={30} />
            </a>
            <a href="https://www.youtube.com/channel/" target="_blank" rel="noopener noreferrer">
              <AiFillYoutube size={30} />
            </a>
          </div>
        </div>
        <div className="footer-links">
          <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          <a href="/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a>
          <a href="/cookie-policy" target="_blank" rel="noopener noreferrer">Cookie Policy</a>
          <a href="/contact-us" target="_blank" rel="noopener noreferrer">Contact Us</a>
        </div>
      </div>
      
      <div className="newsletter-signup">
        <p>Subscribe to our newsletter for updates:</p>
        <input type="email" placeholder="Enter your email" />
        <button>Subscribe</button>
      </div>
      
      <div className="footer-bottom">
        <p>&copy; 2024 PetCrunch. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;